import cx from 'classnames';

import {
  AnalyticsCard,
  AnalyticsContent,
  type AnalyticsModule,
  Event,
  trackEvent,
} from '~/ui/components/analytics';
import { Button } from '~/v1/components/button/button';
import { FullBleedCTA, FullBleedCTATrigger } from '~/v1/components/fullBleedCta/fullBleedCta';
import { useAspectRatio } from '~/v1/components/image/image.hook';
import { ImageBreakpoint, ImageOrientation } from '~/v1/components/image/image.interface';
import { Link } from '~/v1/components/link/link';
import { Route } from '~/v1/constants/route';
import { TEST_ID } from '~/v1/constants/testId';

import styles from './emphasized.module.scss';
import { Image } from '../../image/image';
import { type ArticleCardType } from '../article/article.interface';

export interface EmphasizedCardProps extends ArticleCardType {
  gtm?: {
    module: AnalyticsModule;
  };
  isEventsPage?: boolean;
  className?: string;
}

export const EmphasizedCard: React.FC<EmphasizedCardProps> = ({
  image,
  eyebrow,
  title,
  ctaText,
  ctaUrl,
  className,
  gtm,
  isEvent,
  isEventsPage,
}) => {
  const [aspectRatio, setAspectRatio] = useAspectRatio(image);

  const CTA = isEvent ? Button : Link;

  return (
    <FullBleedCTA
      label={title}
      data-test-id={TEST_ID.CARD.EMPHASIZED}
      className={cx(styles.root, className, {
        [styles.portrait]: aspectRatio?.orientation === ImageOrientation.PORTRAIT,
        [styles.landscape]: aspectRatio?.orientation === ImageOrientation.LANDSCAPE,
      })}
    >
      {image && (
        <Image
          onAspectRatio={setAspectRatio}
          className={styles.image}
          colSpan={{ [ImageBreakpoint.SM]: 5, [ImageBreakpoint.MD]: 7 }}
          src={image.src}
          alt={image.title}
        />
      )}

      {aspectRatio && (
        <div className={styles.content}>
          {eyebrow && <div className={cx('bodySmallTypography', styles.eyebrow)}>{eyebrow}</div>}
          <div className={cx('modulesTitleTypography', styles.title)}>{title}</div>

          <div className={styles.actionWrapper}>
            <FullBleedCTATrigger>
              <CTA
                href={ctaUrl}
                onClick={() =>
                  trackEvent(Event.CARD_CLICK, {
                    cardModule: gtm?.module,
                    cardType: AnalyticsCard.EMPHASIZED,
                    cardContentType: ctaUrl.includes(Route.GRANT_STORY)
                      ? AnalyticsContent.GRANT_STORY
                      : AnalyticsContent.ARTICLE,
                    cardCTA: ctaText,
                    cardTitle: title,
                  })
                }
                ariaLabel={ctaText}
                withIcon
              >
                {ctaText}
              </CTA>
            </FullBleedCTATrigger>
            {isEvent && !isEventsPage && (
              <Link
                href={Route.EVENTS}
                ariaLabel="See all events"
                className={cx(styles.link, styles.linkEvents)}
                withIcon
              >
                See all events
              </Link>
            )}
          </div>
        </div>
      )}
    </FullBleedCTA>
  );
};
